/* STYLİNG LOGO */

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Sarabun", sans-serif;
}

#root {
  position: relative;
  overflow-x: hidden;
  height: 100%;
}

.logo-svg {
  width: 3%;
  position: fixed;
  top: 45%;
  z-index: 90;
  left: 10px;
}

.moving-circle {
  animation: animatedBackground 5s linear infinite;
}
@keyframes animatedBackground {
  from {
    left: -50%;
  }
  to {
    left: 50%;
  }
}

@media print {
  @page {
    size: A4 var(--orientation);
  }
}
/* STYLİNG LOGO */
